<template>
  <div class="m-crossale-cart-products">
    <div :id="crossaleCartProductsWidgetId" />

    <OSection
      :title-heading="$t('Maybe you forgot to order')"
      v-if="relatedList.length"
      is-centered
      :background="`#F3F4F6`"
      is-not-mobile-padding
      class="o-section--slider"
    >
      <lazy-hydrate when-idle>
        <MProductCarousel
          list-id="forgot_to_order"
          list-name="Maybe you forgot to order"
          :products="relatedList"
          :slider-settings="sliderSettings"
          :scrollable="true"
          :last-empty="true"
          @linkClicked="linkClicked"
          arrow-color="white"
          is-white-card
        />
      </lazy-hydrate>
    </OSection>
  </div>
</template>

<script>
import config from 'config';
import LazyHydrate from 'vue-lazy-hydration';
import MProductCarousel from 'theme/components/molecules/m-product-carousel';
import OSection from 'theme/components/organisms/o-section';
import { getAndStoreRelatedProducts } from 'theme/helpers';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XSM } from 'theme/helpers/breakpoints';

export default {
  name: 'MCrossaleCartProducts',
  components: {
    MProductCarousel,
    OSection,
    LazyHydrate
  },
  props: {
    relatedList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      sliderSettings: {
        type: 'slider',
        // focusAt: 0,
        // startAt: 0,
        perView: 3,
        rewind: false,
        slidePerPage: true,
        gap: 10,
        dragThreshold: true,
        swipeThreshold: false,
        peek: {
          before: 0,
          after: 34
        },
        breakpoints: {
          [BREAKPOINT_LG - 1]: {
            perView: 3.1
          },
          [BREAKPOINT_MD - 1]: {
            perView: 3.1
          },
          [BREAKPOINT_SM - 1]: {
            perView: 3.1,
            swipeThreshold: false,
            dragThreshold: false
          },
          [BREAKPOINT_XSM - 1]: {
            perView: 2,
            swipeThreshold: false,
            dragThreshold: false
          }
        }
      }
    }
  },
  computed: {
    crossaleCartProductsWidgetId () {
      return config.esputnik?.widgetIds?.crossaleCartProducts;
    }
  },
  mounted () {
    window.addEventListener('crosssaleCartLoaded', this.getCrossaleCartProducts)
  },
  beforeDestroy () {
    window.removeEventListener('crosssaleCartLoaded', this.getCrossaleCartProducts)
  },
  methods: {
    getCrossaleCartProducts () {
      getAndStoreRelatedProducts(
        this.crossaleCartProductsWidgetId,
        'crossaleCartProducts',
        config?.entities?.product?.carouselSize,
        true
      )
    },
    linkClicked () {
      this.$store.dispatch('ui/setMicrocart', false)
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/breakpoints';

.m-crossale-cart-products {
  background: var(--white);
  padding-top: var(--spacer-50);

  ::v-deep {
    .o-section {
      .sf-heading__title--h3 {
        font-size: var(--font-size-16);
        font-weight: var(--font-medium);
        font-family: var(--font-family-inter);
        text-transform: none;
      }
    }

    .o-section--slider {
      padding: 32px 0 0;

      @media (max-width: $mobile-max) {
        padding: 0;
        margin-top: var(--spacer-24);
      }
    }

    .product-carousel {
      padding-left: 0;

      .glide__slides {
        padding-left: var(--spacer-16);

        @media (min-width: $tablet-min) {
          padding-left: var(--spacer-24);
        }
      }
    }

    @include for-mobile {
      .glide__slide {
        max-width: 155px;
      }
    }

    .glide__track {
      padding-top: 0;
    }

    .o-section--center {
      padding: 0;
    }

    .sf-carousel__wrapper {
      padding: 0;
    }

    .o-section__heading {
      font-weight: var(--font-medium);
      font-size: var(--font-size-16);
      padding-top: var(--spacer-24);
      padding-right: var(--spacer-16);
      padding-left: var(--spacer-16);

      @media (min-width: $tablet-min) {
        padding: var(--spacer-24) var(--spacer-24) var(--spacer-24);
      }

      .sf-heading__title {
        line-height: initial;
      }
    }

    .sf-product-card {
      padding: 0 0 20px 0;

      &__hidden-basket {
        visibility: visible;
        opacity: 1;
      }
    }

    .sf-price__old {
      margin-right: 0;
    }

    .sf-price__special {
      font-size: var(--spacer-15);
      margin-left: var(--spacer-6);

      @include for-desktop{
        font-size: var(--spacer-13);
      }
    }
  }
}

</style>
